.bookings-tab-container {
    display: flex;
    flex-direction: row;
    gap: 25px;
    white-space: nowrap;
}

.details-container {
    display: inline-flex;
    gap: 2px;
    align-items: center;
    align-self: baseline;
    padding: 10px;

}

.details-with-main-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}