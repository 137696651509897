@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer utilities {
  .subProfile{
    @apply absolute left-0 top-[3.1rem] w-full text-black text-center rounded-xl z-10 p-2 shadow-xl hidden
  }

  .pop-up{
     background-image: linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% );
  }
}
#logo-span{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
    padding: 20px;
    width: 200px;
  object-fit: contain;
  vertical-align: middle;
}
#logo-span > img{
  object-fit: contain;
}
.landing-header-wrapper {
  top: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px 102px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 999;
  height: 90px;
  background-color: #fff;
}
.header-button-one {
  width: 100px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  border: 1px solid #515151;
  white-space: nowrap;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.064px;
}
.header-button-two {
  /* width: fit-content; */
  width: 100px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  background-color: #6c60fe;
  white-space: nowrap;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.064px;
}
