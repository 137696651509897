.mentor-profile-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.bg-cover-container {
  overflow: hidden;
  height: 200px;
  background-image: url("https://ik.imagekit.io/epdgvqtwk/general_assets/bgCover.jpeg");
  background-position: center;
  background-size: cover;
}
.mentor-details-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.btns-container {
  width: 100%;
  gap: 15px;
}
.like-btn,
.comments-btn {
  border: 1px solid #d3d3d3;
  padding: 5px 8px;
  border-radius: 5px;
}
.appreciate-btn {
  display: flex;
  align-items: center;
  background-color: black;
  color: white;
  padding: 16px 24px;
  font-size: 20px;
  border-radius: 5px;
  font-weight: 500;
  line-height: 19px;
}
.profile-pic-container {
  position: absolute;
  top: -150px;
}
.profile-pic {
  display: inline-block;
  border-radius: 50%;
  object-fit: contain;
  overflow: hidden;
  height: 200px;
  width: 200px;
  border: 10px solid white;
}
.profile-pic > img {
  width: 100%;
  height: 100%;
  display: block;
}
.speaker-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background-color: #f0effe;
  bottom: 10px;
  right: 35px;
}
.username-text {
  font-size: 38px;
  font-weight: 500;
}
.designation-text {
  font-size: 22px;
  font-weight: 400;
}
.loc-exp-details {
  display: flex;
  gap: 20px;
  color: #636b78;
  overflow: hidden;
}
.loc-exp-details div {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.next-availability-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 80px;
  vertical-align: center;
  text-align: center;
  gap: 8px;
  font-size: 22px;
  font-weight: 500;
  padding: 10px 15px;
  box-shadow: 0px 8px 45px 0px #464c8826;
}
.available-time,
.available-day,
.available-date-month {
  color: #10de16;
  padding-right: 5px;
}
.available-date-month,
.available-day {
  border-right: 1px solid gray;
}
.book-btn {
  font-size: 20px;
  font-weight: 500;
  color: #f9f9fa;
  background-color: #6c63ff;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100px;
}

.overview-experience-and-availabilities {
  width: 100%;
  justify-content: space-between;
}

.overview-experience-and-availabilities
  .overview-background-and-exp-container
  .heading {
  color: #6c63ff;
  font-size: 24px;
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 5px;
}

.overview-experience-and-availabilities
  .overview-background-and-exp-container
  .content {
  border-radius: 8px;
  border: 1px solid #d1d4d6;
  padding: 15px;
  font-size: 22px;
  font-weight: 400;
  word-spacing: 2px;
  line-height: 40px;
}

.overview-background-and-exp-container,
.availabilities-and-similar-profiles-container {
  display: flex;
  flex-direction: column;
  padding: 10px 40px;
  gap: 60px;
}

.overview-details-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.session-availabilities-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.session-availabilities-container .heading {
  font-size: 26px;
  font-weight: 500;
  padding: 0 15px;
}
.overview-details-container .heading-sect .browse-similar-mentors-btn {
  display: flex;
  gap: 5px;
  font-size: 18px;
  font-weight: 500;
  color: #6c63ff;
  align-items: center;
  justify-content: center;
}
.overview-details-container .heading-sect .browse-similar-mentors-btn span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-exp{
  font-size: 20px;
  font-weight: 500;
  color: #f9f9fa;
  background-color: #000000;
  border-radius: 8px;
  padding: 10px;
}

interaction-container:hover{
  cursor: url("https://ik.imagekit.io/epdgvqtwk/general_assets/waitingcursor.png"), auto;
}
