.MentorExperienceCard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
}

.org-and-role {
  gap: 10px;
}
.designation {
  font-size: 24px;
  font-weight: 500;
  color: #3f3d4d;
}
.organization {
  color: #6a6977;
  font-size: 16px;
  font-weight: 500;
}
.experience-description {
  color: #82818d;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}


.work-period {
  color: #3f3d4d;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  background-color: #d1d4d6;
  border-radius: 5px;
  padding: 0px 10px;
}
