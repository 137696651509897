@tailwind base;
@tailwind components;
@tailwind utilities;



@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #FFFFFF;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #6c63ff;
    border-radius: 4rem;
  }
}

@layer components {
  .footer-data {
    @apply text-[16px] font-CircularStdMedium font-normal leading-6 text-[#646A69] z-10;
  }
  .footer-heading {
    @apply text-[16px] font-CircularStdMedium font-normal leading-6 text-[#1D1F1E];
  }
  .signUpPage-text {
    @apply font-CircularStdMedium whitespace-pre-line text-white text-5xl font-medium leading-[64px] text-center;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Circular Std Medium";
  src: url("./assets/fonts/circular-std-medium-500.ttf");
}
@font-face {
  font-family: "Circular Std Bold";
  src: url("./assets/fonts/circular-std-bold.ttf");
}
@font-face {
  font-family: "Circular Std Black";
  src: url("./assets/fonts/CircularStd-Black.otf") format("otf");
}
@import url(https://db.onlinewebfonts.com/c/f1a22f6f15d272c7aa56da8b2c91f5e5?family=Circular+Std+Black);
/* body {
    overflow-y: hidden; 
    overflow-x: hidden;
    -ms-overflow-style: none; 
    scrollbar-width: none;  
  }
  body::-webkit-scrollbar {
    display: none;
  } */
