.MentorBackgroundDetailsCard {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.education,
.experience,
.fluency {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.education .title,
.experience .title,
.fluency .title {
  display: inline-block;
  width: 100px;
  color: #82818d;
  font-size: 24px;
  font-weight: 400;
}
.education-items,
.experience-items,
.fluency-items {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  gap: 20px;
}

.education-items::-webkit-scrollbar,
.experience-items::-webkit-scrollbar,
.fluency-items::-webkit-scrollbar {
  display: none;
}
.education-item {
  color: #8c3636;
  background-color: #f9bdb5;
  border-radius: 6px;
  padding: 0 16px;
}
.experience-item {
  background-color: #b9b5f9;
  color: #4943ad;
  border-radius: 6px;
  padding: 0 16px;
}
.fluency-item {
  color: #3f3d4d;
  background-color: #fbfbfe;
  border-radius: 6px;
  padding: 0 16px;
  border: 1px solid #d1d4d6;
}
