.layout-wrapper{
    display: flex;
    background-color: white;
    height: 100vh;
}
.sidebar{
    display: flex;
    flex-direction: column;
    width: 80px;
    /* margin-top: 110px;
    margin-left: 25px; */
}
.main-wrapper{
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
}
.header-class{
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100px;
    /* box-shadow: 4px 4px 4px 4px whitesmoke; */
    background-color: #fff; /* Example box shadow */
    position: sticky;
    /* margin-left: 35px; */
}
.content-class{
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1.5rem;
    margin-left: 0.5rem;
    scroll-behavior: smooth;
}
