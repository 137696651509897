

.welcome-container {
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  left: 20px;
}
.horizontal-divider {
  margin-top: 30px;
  padding: 20px;
}
.welcome-text {
  color: #000;
  text-align: left;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
}
.progress-text {
  color: #000;
  text-align: left;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  padding: 32px;
}
.main-text {
  color: #130f26;
  text-align: left;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.impact-text {
  color: #464646;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
}
.chart-box {
  border-width: 2px;
  border-radius: 2px;
  border-color: #6c60fe;
}
.progress-chart {
  margin: auto;
  margin-top: 115px;
  margin-bottom: 150px;
  flex-shrink: 0;
}

.bigger-text {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 100px;
}
.emoji-text {
  color: #b9b5f9;
  font-style: normal;
  white-space: pre-line;
  line-height: 102px; /* 106.25% */
  letter-spacing: -1.92px;
}


.grad-text{
/** TEXT GRADIENT */
  /* color: #0ecbd9; */
 /* background-image: radial-gradient( circle 853px at 9.2% 28.3%,  rgba(96,99,227,1) 0%, rgba(145,108,230,0.94) 36.7% ); */
  /* background-image: linear-gradient(108.7deg, rgba(221, 22, 224, 1) 11%, rgba(111, 22, 190, 1) 88.2%); */
  background-image: radial-gradient(circle farthest-corner at 10% 20%, rgba(171, 102, 255, 1) 0%, rgba(116, 182, 247, 1) 90%);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-stroke: 0.5px purple;
  -webkit-text-fill-color: transparent;
}