.logo-container{
    object-fit: contain;
    height: 80px;
    width: 180px;
}
#logo-span{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 200px;
    padding: 20px;
    object-fit: contain;
    vertical-align: middle;
  }