.multi-color-bg{
        background: linear-gradient(to right,
                    #6C63FF33 0%,
                    #6C63FF33 50%,
                    #0091A033 50%,
                    #0091A033 calc(75% - 1px),
                    #008CFF33 75%
                );
}

.multi-color-bg::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: 50%;
    height: 50%;
    background: #0091A033;
}

.multi-color-bg::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 50%;
    height: 50%;
    background: #008CFF33;    
}

.scroll-width::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }